import React from "react";
import CasinoSection from "../../CasinoSection";
import { Helmet } from "react-helmet";

export default function TableGames() {
  const curDate = new Date();
  const topPar = {
    h4: `Experience the Legacy and Future of Table Games in UK Casinos - ${curDate.getFullYear()}`,
    p: "Table games have been the heartbeat of casinos for centuries, blending strategy, skill, and chance into an unforgettable experience. In the UK, these classics like roulette, blackjack, and poker hold a special place, captivating players both in iconic land-based casinos and on cutting-edge online platforms.\nThis year, we're celebrating the enduring charm of table games with an exciting mix of history, innovation, and timeless entertainment.",
  };

  const rich = {
    id: "par-1",
    h4: "✅ Rich History and Cultural Significance",
    p: "From their roots in ancient civilizations to their rise in elegant British gaming houses, table games have a storied past that continues to inspire. Rediscover the tradition and sophistication that make these games a cornerstone of the UK casino industry.",
  };

  const unmatched = {
    id: "par-2",
    h4: "✅ Unmatched Variety and Thrill",
    p: "Whether you're spinning the roulette wheel, strategizing in blackjack, or bluffing your way to a poker victory, table games offer something for every player. Online platforms now bring these experiences to life with live dealers, HD streaming, and customizable tables for all preferences.",
  };

  const future = {
    id: "par-3",
    h4: "✅ Future-Focused Entertainment",
    p: "The future of table games is brighter than ever. Virtual and augmented reality are transforming gameplay, while AI tools are enhancing strategies for both novices and pros. Safe, responsible gaming remains a priority, ensuring that the thrill of the game is always balanced with player protection.",
  };

  const meta = {
    title: `Table Games in UK Casinos: History, Legacy & Future (${curDate.getFullYear()})`,
    description: `Discover the timeless charm of table gamesFExperience the Best Live Casino in UK casinos. From roulette to poker, explore their rich history, cultural significance, and the innovations shaping their future in ${curDate.getFullYear()}. Play safely and responsibly!"`,
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>

      <CasinoSection />

      <div className="w-100 text-bg-dark text-white pt-5 pb-5 mt-3 information">
        <div className="mt-4">
          <h4>{topPar.h4}</h4>
          {topPar.p.split("\n").map((pi, i) => (
            <p key={i}>{pi}</p>
          ))}
        </div>

        <div id={rich.id} className="mt-4">
          <h4>{rich.h4}</h4>
          <p>{rich.p}</p>
        </div>

        <div id={unmatched.id} className="mt-4">
          <h4>{unmatched.h4}</h4>
          <p>{unmatched.p}</p>
        </div>

        <div id={future.id} className="mt-4">
          <h4>{future.h4}</h4>
          <p>{future.p}</p>
        </div>

        <p>
          Join us as we honor the legacy and embrace the future of table games
          in {curDate.getFullYear()}. Ready to take a seat at the table? The
          excitement awaits!
        </p>
      </div>
    </>
  );
}
