import React from "react";
import CasinoSection from "../../CasinoSection";
import { Helmet } from "react-helmet";

export default function LiveGames() {
  const curDate = new Date();

  const topPar = {
    h2: `Experience the Best Live Casino Games in the UK for ${curDate.getFullYear()}`,
    p: `Live casino games have redefined online gaming, offering an immersive, real-time experience that captures the thrill of a physical casino. From interacting with professional dealers to enjoying state-of-the-art streaming, live games are the pinnacle of online entertainment for UK players.`,
  };

  const what = {
    id: "par-2",
    h4: "🎥 What Are Live Casino Games?",
    p: "Live games are hosted by professional dealers and streamed directly to your device. Players can place bets, make decisions, and interact with the dealer and other players in real time, creating a social and authentic gaming atmosphere.",
  };

  const why = {
    id: "par-3",
    h4: "🎮 Why Are Live Casino Games Popular in the UK?",
    ul: [
      "Authenticity: Get the excitement of a real casino without leaving home.",
      "Convenience: Accessible 24/7 on desktop, tablet, or smartphone.",
      "Social Interaction: Chat with dealers and players, adding a community feel to the game.",
    ],
  };

  const top = {
    id: "par-4",
    h4: "🌟 Top Live Games in UK Casinos",
    ul: [
      "Live Roulette: Spin the iconic wheel and try your luck on your favorite bets.",
      "Live Blackjack: Test your strategy against the dealer in this thrilling game of skill.",
      "Live Game Shows: Enjoy interactive games like Crazy Time and Monopoly Live for a fun twist.",
    ],
  };

  const tips = {
    id: "par-5",
    h4: "Tips for Playing Live Casino Games",
    ul: [
      "Learn the Rules: Familiarize yourself with the game mechanics before placing bets.",
      "Set a Budget: Always play responsibly and stick to your limits.",
      "Take Advantage of Bonuses: Look for live casino-specific promotions to boost your experience.",
    ],
  };

  const meta = {
    title: `Best Live Casino Games in the UK ${curDate.getFullYear()}: Real-Time Action & Fun`,
    description: `Discover the top live casino games in the UK for ${curDate.getFullYear()}. Enjoy real-time action with professional dealers, immersive gameplay, and interactive features. Play responsibly and experience the thrill from home!`,
  };

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
      </Helmet>

      <CasinoSection />
      <div className="fw-bold w-100 text-bg-dark text-white pt-4 mb-5 mt-2 information">
        <div className="p-4">
          <div>
            <h2>{topPar.h2}</h2>
            <p>{topPar.p}</p>
          </div>

          <div id={what.id} className="mt-4">
            <h4>{what.h4}</h4>
            <p>{what.p}</p>
          </div>

          <div id={why.id} className="mt-4">
            <h4>{why.h4}</h4>
            <ul>
              {why.ul.map((li, i) => (
                <li key={i}>
                  {li.split(":").map((sp, k) => (
                    <span className={!k ? "fw-bold" : ""} key={k}>
                      {sp} {!k ? ":" : ""}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>

          <div id={top.id} className="mt-4">
            <h4>{top.h4}</h4>

            <ul>
              {top.ul.map((li, i) => (
                <li key={i}>
                  {li.split(":").map((sp, k) => (
                    <span className={!k ? "fw-bold" : ""} key={k}>
                      {sp} {!k ? ":" : ""}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>

          <div id={tips.id} className="mt-4">
            <h4>{tips.h4}</h4>
            <ul>
              {tips.ul.map((li, i) => (
                <li key={i}>
                  {li.split(":").map((sp, k) => (
                    <span className={!k ? "fw-bold" : ""} key={k}>
                      {sp} {!k ? ":" : ""}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-4">
            <p>
              With innovations like augmented reality (AR) and virtual reality
              (VR) on the horizon, live games in the UK are set to become even
              more immersive in the coming years. Take your seat at the table
              today and experience the excitement of live casino games-play
              responsibly and enjoy the journey!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
